import { Breadcrumb, Col, Row, Typography } from "antd"
import { graphql, Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import { TrendingCarousel } from "../components"
import { CategoryCard } from "../components/cards"
import { MaxWidth, WhiteSpace } from "../components/common"
import Container from "../components/container"
import SEO from "../components/seo"

const { Title } = Typography

const ProductsPage = ({ data: { categories, newProducts }, intl }) => {
  console.log(newProducts)
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{intl.formatMessage({ id: "home" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="products">
      <Link to="/products">{intl.formatMessage({ id: "products" })}</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="categories">
      <Link to="/products">{intl.formatMessage({ id: "categories" })}</Link>
    </Breadcrumb.Item>
  ]

  return (
    <Container>
      <SEO title={intl.formatMessage({ id: "products" })} />
      <WhiteSpace size={75} />
      <MaxWidth>
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        <WhiteSpace size={20} />
        <TrendingCarousel products={newProducts} />
        <Title level={3}>Products</Title>
        <Row gutter={[24, 24]} justify="center">
          {categories.nodes.map(category => (
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <CategoryCard category={category} />
            </Col>
          ))}
        </Row>
      </MaxWidth>
    </Container>
  )
}

export const query = graphql`
  query($locale: String) {
    categories: allContentfulProductCategory(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        id
        slug
        title
        image {
          sizes(quality: 100) {
            ...GatsbyContentfulSizes_withWebp
          }
        }
      }
    }
    newProducts: allContentfulProduct(
      filter: { new: { eq: true }, node_locale: { eq: $locale } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        slug
        title
        images {
          sizes(quality: 100) {
            ...GatsbyContentfulSizes_withWebp
          }
        }
        createdAt
        subcategory {
          category {
            slug
          }
        }
        category {
          slug
        }
      }
    }
  }
`

export default injectIntl(ProductsPage)
